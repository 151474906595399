

















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { localize } from 'vee-validate';
import nl from 'vee-validate/dist/locale/nl.json';
localize('nl', nl);

interface Rules {
    [key: string]: any;
}
const customRules: Rules = rules;
Object.keys(customRules).forEach((rule: string) => {
    extend(rule, customRules[rule]);
});

for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, { ...validation });
}

@Component({
    components: {
        ValidationProvider,
        ValidationObserver,
        FormError: () => import('./Error.vue'),
        FormButton: () => import('./Button.vue'),
    },
})
export default class FormBase extends Vue {
    @Prop({ default: 'forms' }) readonly name!: string;

    @Prop({ default: false }) readonly error!: ErrorResponse | boolean;

    @Prop({ default: false }) readonly disabled!: boolean;

    @Prop({ default: false }) readonly hideErrors!: boolean;

    @Prop({ default: false }) readonly hideSubmit!: boolean;

    loading: boolean = false;

    success: boolean = false;

    serverError: ErrorResponse | boolean = false;

    handleErrors(errors: ErrorResponse) {
        this.loading = false;
        this.success = false;
        this.serverError = errors;
        return true;
    }

    createError(message: string) {
        const error: Error = {
            message,
            field: '',
            name: '',
            params: [],
            indexes: [],
        };
        const errorResponse: ErrorResponse = {
            status: 0,
            errors: [],
        };
        errorResponse.errors.push(error);

        this.serverError = errorResponse;

        return errorResponse;
    }

    handleSuccess() {
        this.success = true;
        this.loading = false;
        this.serverError = false;
        setTimeout(() => {
            this.success = false;
            return true;
        }, 2000);
    }

    submit() {
        this.loading = true;
        this.success = false;
        this.serverError = false;
        // @ts-ignore
        return this.$refs.observer.validate().then((valid: boolean) => {
            if (valid) {
                this.handleSubmit();
            } else {
                this.loading = false;
            }
        });
    }

    @Emit('submit')
    handleSubmit() {
        return true;
    }

    private mounted() {
        this.$nextTick().then(() => this.$emit('loaded'));
    }
}
